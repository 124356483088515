<template>
  <div id="reserveSampleLedger">
    <el-dialog
      :title="reserveSampleLedgerFormTitle"
      width="1200px"
      :visible.sync="reserveSampleLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="reserveSampleLedgerDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="reserveSampleLedgerFormRef"
        :model="reserveSampleLedgerForm"
        :rules="reserveSampleLedgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="留样情况" prop="sampleCondition">
              <el-radio-group
                v-model="reserveSampleLedgerForm.sampleCondition"
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              >
                <el-radio :label="1">
                  原辅材料留样
                </el-radio>
                <el-radio :label="2">
                  成品常规留样
                </el-radio>
                <el-radio :label="3">
                  成品重点留样
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="reserveSampleLedgerForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="留样规格" prop="spec">
              <el-input
                v-model="reserveSampleLedgerForm.spec"
                placeholder="请输入留样规格"
                clearable
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="留样批号" prop="batchNo">
              <el-input
                v-model="reserveSampleLedgerForm.batchNo"
                placeholder="请输入留样批号"
                clearable
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="留样数量" prop="quantity">
              <el-input
                v-model="reserveSampleLedgerForm.quantity"
                placeholder="请输入留样数量"
                clearable
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="存放位置" prop="location">
              <el-input
                v-model="reserveSampleLedgerForm.location"
                placeholder="请输入存放位置"
                clearable
                :disabled="reserveSampleLedgerFormTitle !== '新增留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '修改留样台账及领用记录'
                  && reserveSampleLedgerFormTitle !== '留样台账及领用记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="领用情况">
          <vxe-toolbar v-if="reserveSampleLedgerFormTitle === '发放'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="issueList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: activeRowMethod}"
          >
            <vxe-table-column
              field="requisitionDate"
              title="领用日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="purpose"
              title="用途"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="领用数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remainingQuantity"
              title="剩余数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="issuer"
              title="发放人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="recipient"
              title="领用人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="reserveSampleLedgerFormTitle === '发放'
                || reserveSampleLedgerFormTitle === '领用' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="reserveSampleLedgerFormTitle === '发放'
                      && row.issueResult === 1"
                    @click="removeRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="reserveSampleLedgerFormTitle === '领用'
                      && row.issueResult === 1"
                    @click="receiveEvent(row)"
                  >
                    领用
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="reserveSampleLedgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reserveSampleLedgerFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="reserveSampleLedgerFormTitle === '留样台账及领用记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="留样批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入留样批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SAMPLE_LEDGER_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="reserveSampleLedgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column label="留样情况">
        <template slot-scope="scope">
          <span v-if="scope.row.sampleCondition === 1">原辅材料留样</span>
          <span v-if="scope.row.sampleCondition === 2">成品常规留样</span>
          <span v-if="scope.row.sampleCondition === 3">成品重点留样</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="留样规格" />
      <el-table-column prop="batchNo" label="留样批号" />
      <el-table-column prop="quantity" label="留样数量" />
      <el-table-column prop="samplePerson" label="留样人" />
      <el-table-column label="留样日期">
        <template slot-scope="scope">
          <span v-if="scope.row.sampleDate">{{ scope.row.sampleDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="存放位置" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SAMPLE_LEDGER_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SAMPLE_LEDGER_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['SAMPLE_LEDGER_GRANT'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleGrant(scope.$index, scope.row)"
          >
            发放
          </el-button>
          <el-button
            v-if="checkPermission(['SAMPLE_LEDGER_RECEIVE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReceive(scope.$index, scope.row)"
          >
            领用
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="reserveSampleLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addReserveSampleLedger,
  deleteReserveSampleLedger,
  updateReserveSampleLedger,
  selectReserveSampleLedgerInfo,
  selectReserveSampleLedgerList,
  grantReserveSampleLedger,
  receiveReserveSampleLedger
} from '@/api/quality/reserveSampleLedger'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      reserveSampleLedgerDialogVisible: false,
      reserveSampleLedgerFormTitle: '',
      reserveSampleLedgerForm: {
        id: '',
        sampleCondition: '',
        name: '',
        spec: '',
        batchNo: '',
        quantity: '',
        location: ''
      },
      reserveSampleLedgerFormRules: {
        batchNo: [{ required: true, message: '留样批号不能为空', trigger: ['blur', 'change']}]
      },
      reserveSampleLedgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      issueList: []
    }
  },
  created () {
    selectReserveSampleLedgerList(this.searchForm).then(res => {
      this.reserveSampleLedgerPage = res
    })
  },
  methods: {
    reserveSampleLedgerDialogClose () {
      this.$refs.reserveSampleLedgerFormRef.resetFields()
      this.issueList = []
    },
    reserveSampleLedgerFormSubmit () {
      if (this.reserveSampleLedgerFormTitle === '留样台账及领用记录详情') {
        this.reserveSampleLedgerDialogVisible = false
        return
      }
      this.$refs.reserveSampleLedgerFormRef.validate(async valid => {
        if (valid) {
          if (this.reserveSampleLedgerFormTitle === '新增留样台账及领用记录') {
            this.reserveSampleLedgerForm.id = ''
            await addReserveSampleLedger(this.reserveSampleLedgerForm)
          } else if (this.reserveSampleLedgerFormTitle === '修改留样台账及领用记录') {
            await updateReserveSampleLedger(this.reserveSampleLedgerForm)
          } else if (this.reserveSampleLedgerFormTitle === '发放') {
            await grantReserveSampleLedger({
              id: this.reserveSampleLedgerForm.id,
              issueJson: JSON.stringify(this.$refs.xTable.getTableData().tableData)
            })
          }
          this.reserveSampleLedgerPage = await selectReserveSampleLedgerList(this.searchForm)
          this.reserveSampleLedgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.reserveSampleLedgerFormTitle = '新增留样台账及领用记录'
      this.reserveSampleLedgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReserveSampleLedger(row.id)
        if (this.reserveSampleLedgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.reserveSampleLedgerPage = await selectReserveSampleLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.reserveSampleLedgerFormTitle = '修改留样台账及领用记录'
      this.reserveSampleLedgerDialogVisible = true
      this.selectReserveSampleLedgerInfoById(row.id)
    },
    handleGrant (index, row) {
      this.reserveSampleLedgerFormTitle = '发放'
      this.reserveSampleLedgerDialogVisible = true
      this.selectReserveSampleLedgerInfoById(row.id)
    },
    handleReceive (index, row) {
      this.reserveSampleLedgerFormTitle = '领用'
      this.reserveSampleLedgerDialogVisible = true
      this.selectReserveSampleLedgerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.reserveSampleLedgerFormTitle = '留样台账及领用记录详情'
      this.reserveSampleLedgerDialogVisible = true
      this.selectReserveSampleLedgerInfoById(row.id)
    },
    selectReserveSampleLedgerInfoById (id) {
      selectReserveSampleLedgerInfo(id).then(res => {
        this.reserveSampleLedgerForm.id = res.id
        this.reserveSampleLedgerForm.sampleCondition = res.sampleCondition
        this.reserveSampleLedgerForm.name = res.name
        this.reserveSampleLedgerForm.spec = res.spec
        this.reserveSampleLedgerForm.batchNo = res.batchNo
        this.reserveSampleLedgerForm.quantity = res.quantity
        this.reserveSampleLedgerForm.location = res.location
        this.issueList = res.issueList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReserveSampleLedgerList(this.searchForm).then(res => {
        this.reserveSampleLedgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReserveSampleLedgerList(this.searchForm).then(res => {
        this.reserveSampleLedgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReserveSampleLedgerList(this.searchForm).then(res => {
        this.reserveSampleLedgerPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({ issueResult: 1 }, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    receiveEvent (row) {
      receiveReserveSampleLedger({ issueId: row.id }).then(res => {
        this.selectReserveSampleLedgerInfoById(this.reserveSampleLedgerForm.id)
      })
    },
    activeRowMethod ({ row, rowIndex }) {
      if (this.reserveSampleLedgerFormTitle === '发放') {
        return row.issueResult === 1
      }
      return false
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `留样台账及领用记录${'-'+this.reserveSampleLedgerForm.name}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
