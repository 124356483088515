import axios from '@/common/axios'
import qs from 'qs'

export function addReserveSampleLedger (data) {
  return axios({
    method: 'post',
    url: '/quality/ReserveSampleLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteReserveSampleLedger (id) {
  return axios({
    method: 'delete',
    url: '/quality/ReserveSampleLedger/delete/' + id
  })
}

export function updateReserveSampleLedger (data) {
  return axios({
    method: 'put',
    url: '/quality/ReserveSampleLedger/update',
    data: qs.stringify(data)
  })
}

export function selectReserveSampleLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/ReserveSampleLedger/info/' + id
  })
}

export function selectReserveSampleLedgerList (query) {
  return axios({
    method: 'get',
    url: '/quality/ReserveSampleLedger/list',
    params: query
  })
}

export function grantReserveSampleLedger (data) {
  return axios({
    method: 'put',
    url: '/quality/ReserveSampleLedger/updateIssue',
    data: qs.stringify(data)
  })
}

export function receiveReserveSampleLedger (data) {
  return axios({
    method: 'put',
    url: '/quality/ReserveSampleLedger/updateRequisition',
    data: qs.stringify(data)
  })
}
